.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  justify-content: space-between; /* Items are spaced horizontally */
}
.container1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.field {
  margin-bottom: 10px;
}

.horizontal, .vertical {
  display: flex;
  flex-direction: row; /* Items are stacked vertically */
}

.item {
  width: 100px;
  height: 100px;
  background-color: #ccc;
  margin: 5px;
}

/* App.css */


.background-image {
  width: 50%;
  height: auto; /* Maintain the aspect ratio */
}

.overlay-image {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center both horizontally and vertically */
}

/* styles.css */

/* Container styling */
.lines-container {
  text-align: center;
}

/* Line styling */
.line {
  border-top: 1px solid #000; /* Adjust the color and thickness as needed */
  width: 100%; /* Adjust the width as needed */
  margin: 10px auto; /* Adjust the margin as needed */
}

/* End-of-page message styling */
.end-of-page-message {
  margin: 10px 0;
}

/* Texas URL message styling */
.texas-url-message {
  margin: 10px 0;
}
